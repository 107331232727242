<template>
  <div class="release_rules_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">发布规则</div>
      <div class="rightBtn"></div>
    </div>
    <div class="content">
      <p class="textIndent">
        欢迎优秀的您加入软妹社，我们珍视每一位UP主，始终致力于为大家带来最优质的产品与服务。您的作品在软妹社的售卖无时间、次数限制，销售的次数越多，获得的收入就会越多。无论您是在睡觉还是在工作，它们都将持续为您带来源源不断的收入。
      </p>
      <h2>上传规则</h2>
      <p>1.UP主及普通用户上传收费视频比例为2:1，即上传2个免费视频才可上传1个收费视频。</p>
      <p>2.原创举牌up主上传收费视频比例为1:1，即上传1个免费视频才可上传1个收费视频。</p>
      <p>3.视频清晰度需在360P以上，且时长不小于30秒。</p>
      <p>4.套图大于6张才可以设置价格。</p>
      <p>5.审核时间为48小时内，请在[创作中心]查收反馈。</p>
      <p>6.视频中的当事人须满18岁以上，且当事人同意视频被上传分享。</p>
      <h2>审核规则</h2>
      <p>1.原创拍摄、原创剪辑作品，会更容易通过并获得官方推荐。</p>
      <p>2.禁止直接搬运网络视频，重复率高且不容易通过，多次违规将降低账号推荐权重。</p>
      <p>3.禁止在视频/图片中添加个人联系方式或插入广告网址将不会通过审核。</p>
      <p>4.禁止上传幼女、人兽、真实强奸等侵害他人的视频。</p>
      <p>5.加强用户隐私性，允许原创视频为人物面部等重要部分添加遮挡或马赛克。</p>
      <p>6.上传的视频内容不符合上传要求将不会通过审核，如若退回视频未作修改再次发起审核将禁止上传。</p>
      <h2>定价规则</h2>
      <p>1.发布内容默认为免费，用户可根据作品内容质量调整为金币视频。</p>
      <p>
        2.
        <span @click="openPop">认证UP主发布原创举牌长视频</span>
        ，建议定价50-200金币
      </p>
      <p>3.原创举牌作品，建议定价30-50金币</p>
      <p>4.原创剪辑作品，建议定价10-20金币</p>
      <p>5.非原创短片，建议定价1-10金币</p>
      <div class="pb100"></div>
      <!--      <div class="down">-->
      <!--        <h2>官方水印下载：</h2>-->
      <!--        <div class="dwon_box">-->
      <!--          <div class="down_btn" :href="hImg" @click="downhander_h">-->
      <!--            横屏水印下载-->
      <!--          </div>-->
      <!--          <div class="down_btn shu" @click="downhander_v">-->
      <!--            竖屏水印下载-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <ImgDecypt :src="exampleConfig.img" v-if="exampleConfig" />
        </div>
        <div class="close" @click="show = false">
          <svg-icon icon-class="close4" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { queryExampleConfigList } from '@/api/community';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';

export default {
  components: {
    ImgDecypt,
  },
  data() {
    return {
      hImg: require('@/assets/png/watermark_h.png'),
      vImg: require('@/assets/png/watermark_v.png'),
      show: false,
      exampleConfig: undefined,
    };
  },
  methods: {
    async openPop() {
      let res = await this.$Api(queryExampleConfigList);
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          this.exampleConfig = res.data[0];
          this.show = true;
        } else {
          Toast('暂无数据');
        }
      }
    },
    downhander_h() {
      this.downloadIamge(this.hImg, 'weibo_h');
    },
    downhander_v() {
      this.downloadIamge(this.vImg, 'weibo_v');
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/png'); //得到图片的base64编码数据

        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
  },
};
</script>
<style lang="scss" scoped>
.release_rules_warp {
  height: 100%;

  .pb100 {
    padding-bottom: 60px;
  }

  .textIndent {
    text-indent: 28px;
  }

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .content {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 11px 16px 16px 16px;
    -webkit-overflow-scrolling: touch;

    h2 {
      font-size: 16x;
      padding-top: 30px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      color: #ff678f;
      font-weight: bold;
    }

    p {
      line-height: 22px;
      font-size: 14px;
      span {
        font-weight: 800;
        color: #0000ee;
        text-decoration: underline;
      }
    }

    .down {
      padding-bottom: 100px;

      h2 {
        padding-bottom: 20px;
      }

      .dwon_box {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .down_btn {
          color: #ffffff;
          padding: 10px 30px;
          background: linear-gradient(to right, #5f2c82, #49a09d);
          box-shadow: 0px 1px 3px rgba(#49a09d, 0.5);
          border-radius: 20px;
          margin-right: 20px;
          font-size: 16px;
        }

        .shu {
          background: linear-gradient(to right, #556270, #ff6b6b);
          box-shadow: 1px 1px 3px rgba(#ff6b6b, 0.5);
        }
      }
    }
  }

  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0.9);
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .block {
      height: 394px;
      width: 314px;
      border-radius: 6px;
      overflow: hidden;
    }
    .close {
      height: 33px;
      width: 33px;
      margin-top: 20px;
      svg {
        font-size: 33px;
      }
    }
  }
}
</style>
